import React, { useState, useEffect } from "react";
import * as s from "../styles/globalStyles";
import cerberus from "../assets/images/bg/cerberus.gif";
import Layout from "./Layout";
import "../App.css";
import Menu from "./menu";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "../redux/blockchain/blockchainActions";
import { fetchData } from "../redux/data/dataActions";
import Notifications from "./notifications";

function Payouts () {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [loading, setLoading] = useState(false);  
  const [playerId, setPlayerId] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMessageVisible, setErrorMessageVisible] = useState(true);

  console.log(data);

  const payouts = (_account, _playerId) => {
    setLoading(true);
    blockchain.xenomorph.methods
      .Payouts(_playerId)
      .send({
        from: _account,
      })
      .once("error", (err) => {
        setLoading(false);
        console.log(err);
      })
      .then((receipt) => {
        setLoading(false);
        if (!receipt.status) {
          // check receipt.events for error event
          setErrorMessage({errorMessage});
          setErrorMessageVisible(true);
      } else {
          dispatch(fetchData(blockchain.account));
      }
      });
  };

  useEffect (() => {
    dispatch(connect());  
  }, []);

  useEffect(() => {
    if (blockchain.account !== "" && blockchain.xenomorph != null) {
      dispatch(fetchData(blockchain.account));
    }
  }, [blockchain.account, blockchain.xenomorph]);



  return (
    <s.Screen image={cerberus}>
      <Layout />
      <div className={"division1 transpayouts"}/>
      <div className={"translation"} 
                 style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
              <img src={require("../assets/images/cashingout.gif")} className="portraitPYT"/>
              <div>            
                <input className={"inputform"} style={{color: "white", border: "3px solid goldenrod"}}
                  placeholder="Your Xenomorph ID"
                  type="text"
                  value={playerId}
                  onChange={(e) => setPlayerId(e.target.value)}
                  alt="Your Xenomorph ID"
                />
            </div>              
                <button id="spawn-button" className={"button1 textstyle"} 
                disabled={loading ? 1 : 0}
                onClick={(e) => {
                  e.preventDefault();
                  payouts(blockchain.account, playerId);
                }}
                 style={{marginTop: "8px", border: "2px solid goldenrod" }} alt="Cashout button">
                  Cashout
                </button>                  
              </div>  
      
              <div className={"division3 transopayouts"} />
              
      <Menu />
      <Notifications />
    </s.Screen>
  );

}

export default Payouts;