import React from 'react';
import "../App.css";
import * as s from "../styles/globalStyles";
import cerberus from "../assets/images/bg/cerberus.gif";
import Menu from './menu';

function NotFound() {
  return (
    <s.Screen image={cerberus}>
    <div align={"center"}>
    <h1 className={"gradient-text"}> Not Found</h1>
    <p className={"translation"}>
    <img src={(`../assets/images/404.gif`)} /> 
    </p>
    </div>
    <Menu />
  </s.Screen>
  );
}

export default NotFound;