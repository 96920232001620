import React, { useState, useEffect } from 'react';
import * as s from "../styles/globalStyles";
import cerberus from "../assets/images/bg/cerberus.gif";
import Layout from "./Layout";
import Menu from './menu';
import { useDispatch, useSelector } from "react-redux";
import { connect } from "../redux/blockchain/blockchainActions";
import { fetchData } from "../redux/data/dataActions";
import { Link } from 'react-router-dom';

function Arsenal() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);  
  const [selectedPlayer, setSelectedPlayer] = useState(null);  
  const [playerData, setPlayerData] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleClick = (tokenId) => {
    setSelectedPlayer(tokenId);
  };

  const getPlayerData = async (tokenId) => {
    const response = await fetch(`https://xenbox.xyz/xenomorph/${tokenId}.json`)
      const dataplayer = await response.json();

      // Check if dataplayer has the attributes property
      if (!dataplayer.hasOwnProperty("attributes")) {
        console.error("The dataplayer object does not have the 'attributes' property.");
        return;
      }

      // Filter the attributes array to get the trait_type and value properties
      const filteredAttributes = dataplayer.attributes.filter((attribute) => {
        return attribute.hasOwnProperty("trait_type") && attribute.hasOwnProperty("value");
      });
    
      // Create a new array to store the filtered attributes
      const playerAttributes = filteredAttributes.map((attribute) => {
        return {
          trait_type: attribute.trait_type,
          value: attribute.value
        };
      });
    
      // Set the player data in the state
      setPlayerData(playerAttributes);
    };


useEffect(() => {
  if (blockchain.account !== "" && blockchain.xenomorph != null) {
    dispatch(fetchData(blockchain.account));
  }
}, [blockchain.account, blockchain.xenomorph]);

  useEffect(() => {
    if (selectedPlayer) {
      getPlayerData(selectedPlayer);
    }
  }, [selectedPlayer]);

  return (
    <s.Screen image={cerberus}>
      <Layout />
      {blockchain.account === "" || blockchain.xenomorph === null ? (
        <s.Container flex={1} ai={"center"} jc={"center"}>
          <s.SpacerSmall />
          <button className={"button1"}
            onClick={(e) => {
              e.preventDefault();
              dispatch(connect());
            }}
          >
            Begin Apocalypse
          </button>
          <s.SpacerXSmall />
          {blockchain.errorMsg != "" ? (
            <s.TextDescription>{blockchain.errorMsg}</s.TextDescription>
          ) : null}
        </s.Container>
      ) : (
        <div>
            <div className={"division1 transarsenal"} />            
              <div className={"nftloader"}>
              {data.allOwnerOfPlayers.length === 0 ? 
                    <p style={{color: "gold", fontSize: "20px"}} align={"center"}>
                      <img src={require(`../assets/images/noxmph.gif`)} style={{height: "45px"}}  />
                      <br/>
                      <Link to="/Mint" className={"linkbutton"}>Mint Your Xenomorph</Link></p>
                  :
                    data.allOwnerOfPlayers.map((player, index) => {
                      return (
                        <div key={index} className={"texttheme tgoldenrod"} >
                          <img src={(`https://xenbox.xyz/xenomorph/${player.id}.png`)} className={"nftImage"} onClick={() => handleClick(player.id)} />
                          <br />
                          XID: {player.id}
                        </div>
                      );
                    })
                  }
              </div>
              <div className={"detailLoader"}>
                {selectedPlayer && 
                  <div className={"texttheme black"} >
                    <img src={(`https://xenbox.xyz/xenomorph/${selectedPlayer}.png`)} 
                    className={"largeNFT"}/>
                    <div className={"fcs"}>
                   
                                        
                    <div style={{color: "white", marginLeft: "30px"}}>Name: <br />Xenomorph ID:<br />Level: <br />Ferocity:<br />Resilience:<br />Fights: <br />
                    Success Rate:<br />Payout:<br />Hatched to Kill:<br />History: 
                    </div>
                    <div style={{color: "gold"}} >{data.allOwnerOfPlayers.find(p => p.id === selectedPlayer).name}<br />
                    {selectedPlayer}<br />
                    {data.allOwnerOfPlayers.find(p => p.id === selectedPlayer).level}<br />
                    {data.allOwnerOfPlayers.find(p => p.id === selectedPlayer).attack} <br />
                    {data.allOwnerOfPlayers.find(p => p.id === selectedPlayer).defence} <br />
                    {data.allOwnerOfPlayers.find(p => p.id === selectedPlayer).fights} payable <br />
                    {data.allOwnerOfPlayers.find(p => p.id === selectedPlayer).wins} <br />
                    {data.allOwnerOfPlayers.find(p => p.id === selectedPlayer).payout} <br />
                    {data.allOwnerOfPlayers.find(p => p.id === selectedPlayer).hatch}<br />
                    {data.allOwnerOfPlayers.find(p => p.id === selectedPlayer).history} fights<br />
                    </div>
                    <div className={"gradient-text delimiter"} >
                    {playerData.map((data, index) => (
                    <div key={index}>{` ${data.trait_type} | ${data.value}`} <br /></div>
                  ))} </div>
                    </div> 
                  </div>
                }
              </div>
              
                
        <Menu />
      </div>
      )}

    </s.Screen>
  );
}


export default Arsenal;