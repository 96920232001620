import React, { useState, useEffect } from "react";
import * as s from "../styles/globalStyles";
import cerberus from "../assets/images/bg/cerberus.gif";
import Layout from "./Layout";
import "../App.css";
import Menu from "./menu";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "../redux/blockchain/blockchainActions";
import { fetchData } from "../redux/data/dataActions";
import Notifications from "./notifications";

function Assault() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [loading, setLoading] = useState(false);
  const [attackerId, setAttackerId] = useState("");
  const [defenderId, setDefenderId] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMessageVisible, setErrorMessageVisible] = useState(true);

  console.log(data);

  const assault = (_account, _attackerId, _defenderId) => {
    setLoading(true);
    blockchain.xenomorph.methods
      .Assault(_attackerId, _defenderId)
      .send({
        from: _account,
      })
      .once("error", (err) => {
        setLoading(false);
        console.log(err);
      })
      .then((receipt) => {
        setLoading(false);
        if (!receipt.status) {
          // check receipt.events for error event
          setErrorMessage({errorMessage});
          setErrorMessageVisible(true);
      } else {
          dispatch(fetchData(blockchain.account));
      }
      });
  };

  useEffect (() => {
    dispatch(connect());  
  }, []);

  useEffect(() => {
    if (blockchain.account !== "" && blockchain.xenomorph != null) {
      dispatch(fetchData(blockchain.account));
    }
  }, [blockchain.account, blockchain.xenomorph]);

  return (
    <s.Screen image={cerberus}>
      <Layout />
      <div className={"division1 transassault"}   />
      <div className="translation" style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
          <img src={require("../assets/images/assaulter.gif")} className="portraitASLT" />          
            
          <div style={{padding: "2px"}}>
              <input className={"inputform"} style={{color: "white", border: "3px solid darkred", marginBottom: "0px"}}
                placeholder="Your Xenomorph ID"
                type="text"
                value={attackerId}
                onChange={(e) => setAttackerId(e.target.value)}
                alt="Your Xenomorph ID"
              />
          </div>
          <div>
              <input className={"inputform"} style={{color: "white", border: "3px solid darkred", marginTop: "0px"}}
                placeholder=" Enemy ID"
                type="text"
                value={defenderId}
                onChange={(e) => setDefenderId(e.target.value)}
                alt="Insert Enemy ID"
              />
          </div>

            <button className={"buttongold textstyle"}
            disabled={loading ? 1 : 0}
            onClick={(e) => {
              e.preventDefault();
              assault(blockchain.account, attackerId, defenderId);
            }}
          >
            Assault
          </button>
      </div>

      <div className={"division3 transoassault  "} />
      <Menu />
      <Notifications />
      </s.Screen>
  );
}

export default Assault;