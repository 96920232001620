import React, { useState, useEffect } from "react";
import * as s from "../styles/globalStyles";
import cerberus from "../assets/images/bg/cerberus.gif";
import Layout from "./Layout";
import "../App.css";
import Menu from "./menu";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "../redux/blockchain/blockchainActions";
import { fetchData } from "../redux/data/dataActions";
import Notifications from "./notifications";

function UpdateName() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [attackerId, setAttackerId] = useState("");
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMessageVisible, setErrorMessageVisible] = useState(true);

  console.log(data);

  const updateName = (_account, _attackerId, _name) => {
    setLoading(true);
    blockchain.xenomorph.methods
      .updateName(_attackerId, _name)
      .send({
        from: _account,
      })
      .once("error", (err) => {
        setLoading(false);        
        setErrorMessage(err.message);
        console.log(err);
      })
      .then((receipt) => {
        setLoading(false);
        if (!receipt.status) {
          // check receipt.events for error event
          setErrorMessage({errorMessage});
      } else {
          dispatch(fetchData(blockchain.account));
      }
      });
  };

  useEffect (() => {
    dispatch(connect());  
  }, []);

  useEffect(() => {
    if (blockchain.account !== "" && blockchain.xenomorph != null) {
      dispatch(fetchData(blockchain.account));
    }
  }, [blockchain.account, blockchain.xenomorph]);


  return (
    <s.Screen image={cerberus}>
    <Layout />
    <div className={"division1 transname"} />

    <div className="translation" style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
          <img src={require("../assets/images/xmph.gif")} className="portraitUPN" />          
            
          <div style={{padding: "2px"}}>
              <input className={"inputform"} style={{color: "white", border: "3px solid cyan", marginBottom: "0px"}}
                type="text"
                placeholder="Your Xenomorph ID"
                value={attackerId}
                onChange={(e) => setAttackerId(e.target.value)}
                alt="Your Xenomorph ID"
              />
          </div>
          <div>
              <input className={"inputform"} style={{color: "white", border: "3px solid cyan", marginTop: "0px"}}
                type="text"
                placeholder=" New Xenomorph Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                alt="New Xenomorph Name"
              />
          </div>

            <button className={"buttongold textstyle"}
            disabled={loading ? 1 : 0}
            onClick={(e) => {
              e.preventDefault();
              updateName(blockchain.account, attackerId, name);
            }}
          >
            Update
          </button>
      </div>
      <div className={"division3 transoname"} />
      
      <Menu />
      <Notifications />
    </s.Screen>
  );
}

export default UpdateName;