import React from "react";
import {BrowserRouter as Router, Routes , Route, Link} from "react-router-dom";
import Layout from "./pages/Layout";
import Home from './pages/Home';
import Mint from './pages/Mint';
import Arsenal from "./pages/Arsenal";
import UpdateName from './pages/UpdateName';
import HatchXenomorph from './pages/HatchXenomorph';
import Weaponize from './pages/Weaponize';
import Regenerate from './pages/Regenerate';
import Assault from './pages/Assault';
import Debilitate from './pages/Debilitate';
import ClaimAssault from './pages/ClaimAssault';
import ClaimDebilitate from './pages/ClaimDebilitate';
import LevelUp from './pages/LevelUp';
import ResetTimer from './pages/ResetTimer';
import Payouts from './pages/Payouts';
import NotFound from "./pages/NotFound";
import Notifications from "./pages/notifications";
import ActivityTracker from "./pages/activitytracker";

function App() {

  return (
    <Router>
        <Routes>
          <Route path="/" element={<Home/>} />
            <Route path="/mint" element={<Mint/>} />
              <Route path="/update-name" element={<UpdateName/>} />
                <Route path="/arsenal" element={<Arsenal/>} />
                  <Route path="/hatch-xenomorph" element={<HatchXenomorph/>} />
                    <Route path="/weaponize" element={<Weaponize/>} />
                  <Route path="/regenerate" element={<Regenerate/>} />
                <Route path="/assault" element={<Assault/>} />
              <Route path="/debilitate" element={<Debilitate/>} />
            <Route path="/claim-assault" element={<ClaimAssault/>} />
          <Route path="/claim-debilitate" element={<ClaimDebilitate/>} />
            <Route path="/level-up" element={<LevelUp/>} />
              <Route path="/reset-timer" element={<ResetTimer/>} />
                <Route path="/payouts" element={<Payouts/>} />
                  <Route path="/notfound" element={<NotFound/>} />
                <Route path="/notifications" element={<Notifications/>} />
              <Route path="/activitytracker" element={<ActivityTracker/>} />
      </Routes>
    </Router>
  );
}

export default App;
