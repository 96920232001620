import React, { useState, useEffect } from "react";
import * as s from "../styles/globalStyles";
import cerberus from "../assets/images/bg/cerberus.gif";
import Layout from "./Layout";
import "../App.css";
import Menu from "./menu";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "../redux/blockchain/blockchainActions"; 
import { fetchData } from "../redux/data/dataActions";
import Notifications from "./notifications";

function Weaponize() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);      
  const [attackerId, setAttackerId] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMessageVisible, setErrorMessageVisible] = useState(false);
  
    console.log(data);

    const weaponize = (_account, _id) => {
      setLoading(true);
      blockchain.xenomorph.methods
        .weaponize(_id)
        .send({
          from: _account,
        })
        .once("error", (error) => {
          setLoading(false);
          setErrorMessage(error.message);
          setErrorMessageVisible(true);
          console.error(error);
        })
        .then((receipt) => {
          setLoading(false);
          if (!receipt.status) {
            // check receipt.events for error event
            setErrorMessage({errorMessage});
            setErrorMessageVisible(true);
        } else {
            dispatch(fetchData(blockchain.account));
        }
    });
    };

    useEffect (() => {
      dispatch(connect());  
    }, []);

    useEffect(() => {
      if (blockchain.account !== "" && blockchain.xenomorph != null) {
        dispatch(fetchData(blockchain.account));
      }
    }, [blockchain.account, blockchain.xenomorph]);

  return (
    <s.Screen image={cerberus}>
      <Layout />
      <div className={"division1 transweaponize"}/>
      <div className={"translation"} 
                 style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
              <img src={require("../assets/images/ninjaxenomorph.gif")} className="portraitWPN"/>              
              <div>
              <input className={"inputform"} style={{color: "white", border: "3px solid rgb(184, 67, 67)"}}
                type="text"
                placeholder="Your Xenomorph ID"
                onChange={(e) => setAttackerId(e.target.value)}
                alt="Your Xenomorph ID"
              />
          </div>
            <button className={"button1 textstyle"}
            disabled={loading ? 1 : 0}
            onClick={(e) => {
              e.preventDefault();
              weaponize(blockchain.account, attackerId);
            }}
          >
            Weaponize
          </button>
              </div> 
          
              <div className={"division3 transoweaponize"} />              
              
      <Menu />
      <Notifications />
    </s.Screen>
  );
}

export default Weaponize;